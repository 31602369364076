export const PARTNER_LIST_REQUEST = 'PARTNER_LIST_REQUEST';
export const PARTNER_LIST_REQUEST_SUCCESS = 'PARTNER_LIST_REQUEST_SUCCESS';
export const PARTNER_LIST_REQUEST_ERROR = 'PARTNER_LIST_REQUEST_ERROR';

export const PARTNER_CREATE = 'PARTNER_CREATE';
export const PARTNER_CREATE_SUCCESS = 'PARTNER_CREATE_SUCCESS';
export const PARTNER_CREATE_ERROR = 'PARTNER_CREATE_ERROR';

export const PARTNER_UPDATE = 'PARTNER_UPDATE';
export const PARTNER_UPDATE_SUCCESS = 'PARTNER_UPDATE_SUCCESS';
export const PARTNER_UPDATE_ERROR = 'PARTNER_UPDATE_ERROR';

export const PARTNER_DELETE = 'PARTNER_DELETE';
export const PARTNER_DELETE_SUCCESS = 'PARTNER_DELETE_SUCCESS';
export const PARTNER_DELETE_ERROR = 'PARTNER_DELETE_ERROR';
